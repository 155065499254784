<template>

    <a-card :bordered="false" class="dashboard-bar-line header-solid">
        <template #title>
            <h6>{{ subTitle }}</h6>
            <p>change over period <span class="text-success">{{percent}}%</span></p>
        </template>
        <template #extra>
            <a-badge color="primary" class="badge-dot-primary" :text="title" />
        </template>
        
        <apexchart height="400" :type="chartType" :options="cplineChartData.options" :series="cplineChartData.series"></apexchart>
    </a-card>

</template>

<script>

// Bar chart for "Active Users" card.
import ChartLine from '../Charts/ChartLine';

export default ({
    components: {
        ChartLine,
    },
    props: {
        title: {
            type: String,
            default: 'Traffic Overview'
        },
        subTitle: {
            type: String,
            default: 'than last year +20%'
        },
        items: {
            type: Array,
            default: () => []
        },
        colors: {
            type: Array,
            default: () => ["#1890FF", "#B37FEB"]
        },
        numDatasets: {
            type: Number,
            default: 1
        },
        chartType: {
            type: String,
            default: 'line'
        }
    },
    mounted() {
        //this.lineChartData = this.doLineChartDataProcessed();
    },
    computed: {
        cplineChartData() {
            console.log('cplineChartData');
            return this.doLineChartDataProcessed();
        }
    },
    methods: {

        doLineChartDataProcessed() {

            let labels = [];

            let data = [];

            for (let i = 0; i < this.items.length; i++) {

                let label = null;

                if(this.items[i].end_time_ts) {

                    let dt = new Date(this.items[i].end_time_ts * 1000);

                    label = dt.toLocaleString('default', { month: 'short', day: 'numeric' });
                }

                else {

                    label = `period ${i + 1}`;

                }

                
                let value = this.items[i].value;

                labels.push(label);

                data.push(value);

            }


            let first = data[0];

            let last = data[data.length - 1];

            let diff = last - first;

            let percent = first==0? 0: (diff / first) * 100;

            this.percent = percent.toFixed(1);

            // this.lineChartData.labels = labels;

            this.lineChartData.options = {
                chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: labels
        }
            }

            this.lineChartData.series = [
                {
                    name: this.title,
                    data: data,
                    maxBarThickness: 6
                }
            ]
            return this.lineChartData;
        }
    },
    data() {
        return {

            // Data for line chart.
            lineChartData: {
                options: {},
                series: [],
            },
        }
    },
})

</script>